button {
  width: 100%;
  position: relative;
  background: var(--color-primary);
  border: none;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow:  2px 2px 6px #bebebe,
  -2px -2px 6px #ffffff;

  p, i {
    white-space: pre-wrap;
    color: var(--white);
    font-size: var(--font_size_3);
  }
  p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  i {
    position: absolute;
    right: 8px;
  }

  i.move-left {
    right: unset;
    left: 8px;
  }
}

button:active {
  box-shadow:  1px 1px 1px #9e9e9e,
  -1px -1px 4px #cccccc;
}

button.disabled {
  opacity: 0.4;
  pointer-events: none;
  box-shadow: none;

}

button.primary {

}

button.secondary {
  background: var(--white);
  //border: var(--color-primary);
  p, i {
    color: var(--color-primary);
  }
}

button.success {

}

button.error {

}

button.info {

}

button.warning {
}

button.back_button {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: none;
  padding: 0;
  margin-top: 14px;
}

button.align-left {
  text-align: left;
  padding-left: 51px;
  p {
    margin-left: unset;
  }
}

div.fa-rounded {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;


  i {
    position: relative;
    right: unset;
    color: var(--color-primary);
    margin: 0;
    font-size: 1.1em;
    top: 1px;
  }
}
