.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transition: 50ms;
  img {

  }


  -webkit-animation: fadein 1200ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1200ms; /* Firefox < 16 */
  -ms-animation: fadein 1200ms; /* Internet Explorer */
  -o-animation: fadein 1200ms; /* Opera < 12.1 */
  animation: fadein 1200ms;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}