@import "../styling";
.header {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background: var(--color-primary);
  max-height: 50px;
  padding: 8px;
  padding-top: 14px;
  z-index: 1000;


  h1 {
    font-size: calc(0.8 * var(--font_size_3));
    max-width: 180px;
    text-align: right;
    font-weight: 300;
    line-height: 0.9em;
  }

  img.logo {
    max-height: 99%;
    height: 99%;
    max-height: 39px;
    object-fit: contain;
  }
}