.toast-message {
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 8px;
  background: var(--black_alpha88);
  position: absolute;
  top: -400%;
  left: 0;
  transition: 500ms;
  align-content: center;
  align-items: center;
  justify-content: center;
}