.news-carousel {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  witdh: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin-bottom: 19px;


  .news-item {
    display: flex;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 11px;
    min-width: 110px;
    max-width: 110px;
    height: calc(110px * 1.3);
    margin-right: 7px;
    position: relative;

    p {
      position: absolute;
      top: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font_size_2);
      line-height: 15px;
      display: flex;
      align-items: flex-end;
      text-align: left;
      margin-left: 4px;
      margin-right: 4px;
      width: calc(100% - 10px);
      text-transform: none;
      line-height: 1.1em;
      color: #FFFFFF;
      z-index: 1;
    }

    p.call-to-action {
      position: absolute;
      bottom: 5px;
      font-weight: 300;
      font-size: var(--font_size_2);
    }

    .toner {
      display: flex;
      top: 0;
      border-radius: 12px;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: var(--color-primary);
      opacity: 0.4;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      opacity: 0.6;
      border-radius: 12px;
    }
  }

  .news-carousel::-webkit-scrollbar {
    display: none;
  }
}

