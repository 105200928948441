.authorization {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-primary);

  .authorization-window {
    width: 100vw;
    height: 100vh;
    padding: 8px;
    
    img {
      width: 30vw;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2vh;
      margin-bottom: 14vh;
      display: flex;
    }

    h1 {
      //width: 100%;
      font-size: var(--font_size_3);
      font-weight: 300;
      text-align: center;
      margin-top: 10vh;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 3vh;
      letter-spacing: 2px;
    }

    .content {
      margin-top: 8px;
      display: flex;
      width: 100%;
      min-height: 10vh;
      border-radius: 8px;
      background: var(--gray_100);
      flex-direction: column;
      align-items: center;
      align-content: center;
      padding-top: 8px;

      button {
        width: 95%;
      }
    }
  }
}

.authorization-landing {
  background: #FFF;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.authorization-window {

}