.lower-menu {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 73.96px;
  left: 0px;
  background: var(--color-primary);
  //box-shadow: 0px 1px 15px rgba(114, 124, 150, 0.3);
  border-radius: 10px 10px 0 0;
  display:  flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 22;
}


.lower-menu-button {
  padding-top: 7px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font_size_1);
    color: #fff;
    line-height: 11px;
    /* identical to box height */
    text-align: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    text-transform: none;
    line-height: 11px;
    text-align: center;
  }
}