.app-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  //min-height: 40vh;
  //max-height: calc(100% - 2280px);
  overflow: hidden;
  margin-bottom: 60px;
  //background: #f00;
  //border: 2px solid #3dcc4b;
}

.menu {
  width: 100%;
  margin: 8px;
  overflow: hidden;
  marging-top: 0;
  background: var(--white);
  padding: 20px 15px 13px;

  background: #FFFFFF;
  /* Тень двойная */

  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.08), 0px 2px 7px rgba(0, 0, 0, 0.06);
  border-radius: 7px;


}