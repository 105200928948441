@use "./color-system.css";
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
$min_font_size_in_px: 7;

* {
    //font-family: 'Roboto', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--white);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root {
    --main_background: var(--color-primary);
}

html, body {
    background: var(--main_background);
}

.inactive {
    opacity: 0.4;
    pointer-events: none;
}

:root {
    $value: $min_font_size_in_px;
    @for $i from 1 through 15 {
        --font_size_#{$i}: #{$value * 1px};
        $value: $value * 1.61;
    }
}

