.round-up {
  //border-radius: 8px 8px 0 0;
  border-bottom: none;
}

.round-down {
  //border-radius: 0 0 8px 8px;
  border-top: none;
  border-bottom: 1px solid var(--steel_gray_120);
}

.accordion {
  border: 1px solid var(--steel_gray_120);
  border-bottom: none;
  overflow: hidden;

  .accordion-main-trigger {
    display: flex;
    flexDirection: row;

    .number {
      font-weight: 600;
      min-width: 40px;
      max-width: 40px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-right: 1px solid var(--steel_gray_120);
      margin-right: 14px;
    }

    .accordeon-title {
      //color: var(--black);
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }



  //.accordion__button:before {
  //  display: inline-block;
  //  content: '';
  //  height: 10px;
  //  width: 10px;
  //  margin-right: 12px;
  //  border-bottom: 2px solid currentColor;
  //  border-right: 2px solid currentColor;
  //  transform: rotate(-45deg);
  //}
  //
  //.accordion__button[aria-expanded='true']::before,
  //.accordion__button[aria-selected='true']::before {
  //  transform: rotate(45deg);
  //
  //}

  .accordion__button {
    * {
      color: var(--color-primary);
    }
  }
  .accordion__button[aria-expanded='false'] {
    //color: var(--color-primary);
  }

  .accordion__button[aria-expanded='true'] {
    background: var(--color-primary);
    * {
      color: var(--white);
    }
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
    padding: 14px;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

}